import React, { lazy } from 'react';
import { 
    Authenticated,
    CanAccess, 
    Refine,
    Action,
    IResourceItem,
    useParsed,
    useTranslate 
} from "@refinedev/core";

import { RefineKbarProvider } from "@refinedev/kbar";
import {
    notificationProvider,
    // ThemedLayoutV2,
    // ThemedSiderV2,
    // ErrorComponent,
} from "@refinedev/antd";

import { ThemedLayoutV2 } from "components/themedLayout"; 
import { ThemedSiderV2 } from "components/themedLayout/sider";
import { Footer } from 'antd/es/layout/layout';

import routerProvider, {
    CatchAllNavigate,
    NavigateToResource,
    UnsavedChangesNotifier,
    DocumentTitleHandler,
} from "@refinedev/react-router-v6";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import {
    ShoppingOutlined,
    UsergroupAddOutlined,
    ShopOutlined,
    StarOutlined,
    DashboardOutlined,
    GlobalOutlined,
    AppstoreOutlined,
    ApartmentOutlined,
    LayoutOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import jsonServerDataProvider from "@refinedev/simple-rest";

import {
    authProvider, 
} from "./providers";

import "dayjs/locale/en";

import { useTranslation } from "react-i18next";
import { Header, Title } from "components";
import { ConfigProvider } from "context";
import { TOKEN_KEY, REFRESH_TOKEN_KEY } from "./constants";

import axios, { AxiosRequestConfig } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

// Dynamic import by lazy react
const ErrorComponent = lazy(() =>
	import('@refinedev/antd').then((module) => ({
		default: module.ErrorComponent,
	}))
);

const LoginPage = lazy(() =>
	import('./pages/auth/login').then((module) => ({
		default: module.LoginPage,
	}))
);

const MiniDashboardSectionOfList = lazy(() =>
	import('./pages/mini-dashboard/section-of').then((module) => ({
		default: module.MiniDashboardSectionOfList,
	}))
);
const MiniDashboardSectionOfCreate = lazy(() =>
	import('./pages/mini-dashboard/section-of').then((module) => ({
		default: module.MiniDashboardSectionOfCreate,
	}))
);
const MiniDashboardSectionOfEdit = lazy(() =>
	import('./pages/mini-dashboard/section-of').then((module) => ({
		default: module.MiniDashboardSectionOfEdit,
	}))
);

const MiniDashboardMiniAppOfList = lazy(() =>
	import('./pages/mini-dashboard/miniapp-of').then((module) => ({
		default: module.MiniDashboardMiniAppOfList,
	}))
);
const MiniDashboardMiniAppOfCreate = lazy(() =>
	import('./pages/mini-dashboard/miniapp-of').then((module) => ({
		default: module.MiniDashboardMiniAppOfCreate,
	}))
);
const MiniDashboardMiniAppOfEdit = lazy(() =>
	import('./pages/mini-dashboard/miniapp-of').then((module) => ({
		default: module.MiniDashboardMiniAppOfEdit,
	}))
);

const MiniDashboardLayoutList = lazy(() =>
	import('./pages/mini-dashboard/layout').then((module) => ({
		default: module.MiniDashboardLayoutList,
	}))
);
const MiniDashboardLayoutCreate = lazy(() =>
	import('./pages/mini-dashboard/layout').then((module) => ({
		default: module.MiniDashboardLayoutCreate,
	}))
);
const MiniDashboardLayoutEdit = lazy(() =>
	import('./pages/mini-dashboard/layout').then((module) => ({
		default: module.MiniDashboardLayoutEdit,
	}))
);

const MiniDashboardSectionList = lazy(() =>
	import('./pages/mini-dashboard/section').then((module) => ({
		default: module.MiniDashboardSectionList,
	}))
);
const MiniDashboardSectionCreate = lazy(() =>
	import('./pages/mini-dashboard/section').then((module) => ({
		default: module.MiniDashboardSectionCreate,
	}))
);
const MiniDashboardSectionEdit = lazy(() =>
	import('./pages/mini-dashboard/section').then((module) => ({
		default: module.MiniDashboardSectionEdit,
	}))
);

const AppstoreTranslateList = lazy(() =>
	import('./pages/appstore/translates').then((module) => ({
		default: module.AppstoreTranslateList,
	}))
);
const AppstoreTranslateEdit = lazy(() =>
	import('./pages/appstore/translates').then((module) => ({
		default: module.AppstoreTranslateEdit,
	}))
);

const AppstorePermissionList = lazy(() =>
	import('./pages/appstore/permission').then((module) => ({
		default: module.AppstorePermissionList,
	}))
);

const AppstoreMiniAppOfList = lazy(() =>
	import('./pages/appstore/miniapp-of').then((module) => ({
		default: module.AppstoreMiniAppOfList,
	}))
);
const AppstoreMiniAppOfCreate = lazy(() =>
	import('./pages/appstore/miniapp-of').then((module) => ({
		default: module.AppstoreMiniAppOfCreate,
	}))
);
const AppstoreMiniAppOfEdit = lazy(() =>
	import('./pages/appstore/miniapp-of').then((module) => ({
		default: module.AppstoreMiniAppOfEdit,
	}))
);

const AppstoreMiniAppVersionList = lazy(() =>
	import('./pages/appstore/miniapp-version').then((module) => ({
		default: module.AppstoreMiniAppVersionList,
	}))
);
const AppstoreMiniAppVersionCreate = lazy(() =>
	import('./pages/appstore/miniapp-version').then((module) => ({
		default: module.AppstoreMiniAppVersionCreate,
	}))
);
const AppstoreMiniAppVersionEdit = lazy(() =>
	import('./pages/appstore/miniapp-version').then((module) => ({
		default: module.AppstoreMiniAppVersionEdit,
	}))
);
const AppstoreMiniAppVersionPermission = lazy(() =>
	import('./pages/appstore/miniapp-version').then((module) => ({
		default: module.AppstoreMiniAppVersionPermission,
	}))
);

const AppstoreSDKList = lazy(() =>
	import('./pages/appstore/sdk').then((module) => ({
		default: module.AppstoreSDKList,
	}))
);
const AppstoreSDKCreate = lazy(() =>
	import('./pages/appstore/sdk').then((module) => ({
		default: module.AppstoreSDKCreate,
	}))
);
const AppstoreSDKEdit = lazy(() =>
	import('./pages/appstore/sdk').then((module) => ({
		default: module.AppstoreSDKEdit,
	}))
);

const AppstoreUserList = lazy(() =>
	import('./pages/appstore/users').then((module) => ({
		default: module.AppstoreUserList,
	}))
);
const AppstoreUserCreate = lazy(() =>
	import('./pages/appstore/users').then((module) => ({
		default: module.AppstoreUserCreate,
	}))
);
const AppstoreUserEdit = lazy(() =>
	import('./pages/appstore/users').then((module) => ({
		default: module.AppstoreUserEdit,
	}))
);

const AppstoreMiniappList = lazy(() =>
	import('./pages/appstore/miniapp').then((module) => ({
		default: module.AppstoreMiniappList,
	}))
);
const AppstoreMiniappCreate = lazy(() =>
	import('./pages/appstore/miniapp').then((module) => ({
		default: module.AppstoreMiniappCreate,
	}))
);
const AppstoreMiniappEdit = lazy(() =>
	import('./pages/appstore/miniapp').then((module) => ({
		default: module.AppstoreMiniappEdit,
	}))
);
const AppstoreMiniappShow = lazy(() =>
	import('./pages/appstore/miniapp').then((module) => ({
		default: module.AppstoreMiniappShow,
	}))
);

const API_URL = process.env.REACT_APP_API_URL;

// =========== begin axios config ======
const axiosInstance = axios.create();

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest: any) => 
    axiosInstance
        .post(`${API_URL}/token-refresh`, {refresh_token: localStorage.getItem(REFRESH_TOKEN_KEY)})
        .then((tokenRefreshResponse) => {
            localStorage.setItem(TOKEN_KEY, tokenRefreshResponse.data.token);

            failedRequest.response.config.headers["Authorization"] =
                "Bearer " + tokenRefreshResponse.data.token;

            return Promise.resolve();
        });

// Instantiate the interceptor
createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

// https://refine.dev/docs/tutorial/understanding-authprovider/create-authprovider/
// axios-auth-refresh
axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (request.headers) {
        request.headers["Authorization"] = `Bearer ${token}`;
    } else {
        request.headers = {
            Authorization: `Bearer ${token}`,
        };
    }
    return request;
});
// =========== end axios config ======

import "@refinedev/antd/dist/reset.css";
import "./index.css";

const App: React.FC = () => {

    const AUDIT_LOG_API_URL = process.env.REACT_APP_AUDIT_LOG_API_URL;

    const dataProvider = jsonServerDataProvider(API_URL, axiosInstance);

    /// === APPSTORE API ==
    const APPSTORE_API_URL = process.env.REACT_APP_APPSTORE_API_URL;
    const appstoreProvider = jsonServerDataProvider(APPSTORE_API_URL, axiosInstance);

    /// === DASHBOARD API ==
    const DASHBOARD_API_URL = process.env.REACT_APP_DASHBOARD_API_URL;
    const dashboardProvider = jsonServerDataProvider(DASHBOARD_API_URL, axiosInstance);

    const { t, i18n } = useTranslation();

    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };

    const customTitleHandler = (options:{
        resource?: IResourceItem,
        action?: Action,
        params?: Record<string, string | undefined>,
        pathname?: string,
        autoGeneratedTitle: string,
    }) => {

        let title = "MiniApps"; // Default title
        const { action, params, pathname, resource, autoGeneratedTitle } = options;
        if (resource && action) {
            title = `MiniDashboard | ${resource.label}`;
        }
        return title;
    };

    return (
        <BrowserRouter>
            <ConfigProvider>
                <RefineKbarProvider>
                    <Refine
                        auditLogProvider={{
                            get: async ({ resource, meta }) => {
                                const { data } = await jsonServerDataProvider(AUDIT_LOG_API_URL).getList({
                                    resource: "audit-logs",
                                    filters: [
                                        {
                                            field: "resource",
                                            operator: "eq",
                                            value: resource,
                                        },
                                        {
                                            field: "meta.id",
                                            operator: "eq",
                                            value: meta?.id,
                                        },
                                    ],
                                });

                                return data;
                            },
                            create: (params) => {
                                return jsonServerDataProvider(AUDIT_LOG_API_URL).create({
                                    resource: "audit-logs",
                                    variables: params,
                                });
                            },
                            update: async ({ id, name }) => {
                                const { data } = await jsonServerDataProvider(AUDIT_LOG_API_URL).update({
                                    resource: "audit-logs",
                                    id,
                                    variables: { name },
                                });
                                return data;
                            },
                        }}
                        routerProvider={routerProvider}
                        dataProvider={{
                            default: appstoreProvider,
                            appstore: appstoreProvider,
                            dashboard: dashboardProvider,
                        }}
                        authProvider={authProvider(APPSTORE_API_URL)}
                        i18nProvider={i18nProvider}
                        options={{
                            syncWithLocation: true,
                            warnWhenUnsavedChanges: true,
                        }}
                        notificationProvider={notificationProvider}
                        resources={[
                            {
                                name: "sdk-setting",
                                meta: {
                                    label: t("menus.sdkSetting"),
                                    icon: <AppstoreOutlined />,
                                },
                            },
                            {
                                name: "sdks",
                                list: "/sdks",
                                create: "/sdks/create",
                                edit: "/sdks/edit/:id",
                                clone: "/sdks/clone/:id",
                                meta: {
                                    label: t("menus.SDKs"),
                                    parent: "sdk-setting",
                                    dataProviderName: "appstore",
                                    canDelete: false,
                                },
                            },
                            {
                                name: "sdk-miniapps",
                                list: "/sdk-miniapps",
                                create: "/sdk-miniapps/create",
                                edit: "/sdk-miniapps/edit/:id",
                                meta: {
                                    label: t("menus.miniappOfSdk"),
                                    parent: "sdk-setting",
                                    dataProviderName: "appstore",
                                },
                            },
                            {
                                name: "miniapp-versions",
                                list: "/miniapp-versions",
                                create: "/miniapp-versions/create",
                                edit: "/miniapp-versions/edit/:id",
                                clone: "/miniapp-versions/clone/:id",
                                meta: {
                                    label: t("menus.miniappVersions"),
                                    parent: "sdk-setting",
                                    dataProviderName: "appstore",
                                },
                            },
                            {
                                name: "miniapps",
                                list: "/miniapps",
                                create: "/miniapps/create",
                                edit: "/miniapps/edit/:id",
                                show: "/miniapps/show/:id",
                                meta: {
                                    label: t("menus.miniapps"),
                                    parent: "sdk-setting",
                                    dataProviderName: "appstore",
                                },
                            },                      
                            {
                                name: "permissions",
                                list: "/permissions", 
                                meta: {
                                    label: t("menus.permissions"),
                                    parent: "sdk-setting",
                                    dataProviderName: "appstore", 
                                },
                            },                        
                            {
                                name: "miniapp-permissions",
                                edit: "/miniapp-versions/:id/permissions", 
                                meta: {
                                    dataProviderName: "appstore",
                                    hide: true,
                                },
                            },
                            {
                                name: "mini-dashboard",
                                meta: {
                                    label: 'Mini Dashboard',
                                    icon: <LayoutOutlined />,
                                },
                            },
                            {
                                name: "layouts",
                                list: "/",
                                create: "/layouts/create",
                                edit: "/layouts/edit/:id",
                                clone: "/layouts/clone/:id",
                                meta: {
                                    label: t("menus.layouts"),
                                    parent: "mini-dashboard",
                                    dataProviderName: "dashboard",
                                },
                            },
                            {
                                name: "layout-sections",
                                list: "/layout-sections",
                                create: "/layout-sections/create",
                                edit: "/layout-sections/edit/:id",
                                meta: {
                                    label: 'Sections Of',
                                    parent: "mini-dashboard",
                                    dataProviderName: "dashboard",
                                    hide: true,
                                },
                            },
                            {
                                name: "sections",
                                list: "/sections",
                                create: "/sections/create",
                                edit: "/sections/edit/:id",
                                clone: "/sections/clone/:id",
                                meta: {
                                    label: t("menus.sections"),
                                    parent: "mini-dashboard",
                                    dataProviderName: "dashboard",
                                },
                            },
                            {
                                name: "section-types",
                                meta: {
                                    hide: true,
                                    parent: "mini-dashboard",
                                    dataProviderName: "dashboard",
                                },
                            },
                            {
                                name: "section-miniapps",
                                list: "/section-miniapps",
                                create: "/section-miniapps/create",
                                clone: "/section-miniapps/create/:section_id",
                                edit: "/section-miniapps/edit/:id",
                                meta: {
                                    label: t("menus.miniappOfSection"),
                                    parent: "mini-dashboard",
                                    dataProviderName: "dashboard",
                                    canDelete: false,
                                    hide: true,
                                },
                            },
                            {
                                name: "vouchers",
                                list: "/vouchers", 
                                meta: {
                                    label: t("menus.vouchers"),
                                    parent: "mini-dashboard",
                                    dataProviderName: "dashboard",
                                    canDelete: false,
                                    hide: true,
                                },
                            }, 
                            {
                                name: "translates",
                                list: "/translates",
                                edit: "/translates/edit/:id",
                                meta: {
                                    label: t("menus.translates"),
                                    icon: <GlobalOutlined />,
                                    dataProviderName: "appstore",
                                },
                            },       

                            {
                                name: "users",
                                list: "/users",
                                create: "/users/create",
                                edit: "/users/edit/:id",
                                meta: {
                                    label: t("menus.developers"),
                                    icon: <UsergroupAddOutlined />,
                                },
                            },
                            // This will add an item to `<Sider/>` with route `/home-page`
                            { 
                                name: "account",
                                meta: {
                                    label: t("menus.accountSetting"),
                                    icon: <SettingOutlined />,
                                    hide: true,
                                }, 
                            },
                            { 
                                name: "change-password", 
                                // list: "/account/change-password", 
                                edit: "/account/change-password", 
                                meta: {
                                    parent: "account",
                                    label: t("menus.changePassword"),
                                    hide: true,
                                },
                            },
                        ]}
                    >
                        <Routes>
                            <Route
                                element={
                                    <Authenticated
                                        fallback={
                                            <CatchAllNavigate to="/login" />
                                        }
                                    >
                                        <ThemedLayoutV2 
                                            Header={Header}
                                            //Title={Title}
                                            Sider={() => (
                                                <div
                                                    style={{
                                                        borderRight: '1px solid rgb(217, 217, 217)',
                                                        backgroundColor: 'white',
                                                    }}
                                                >
                                                    <ThemedSiderV2 Title={Title}/>
                                                </div>
                                            )}
                                        >
                                            <CanAccess
                                                fallback={<div>Unauthorized!</div>}
                                            >
                                                <Outlet />
                                            </CanAccess>
                                            <Footer
                                                style={{
                                                    alignItems: 'center',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                © Powered by ALI Corporation version 1.0.2
                                            </Footer>
                                        </ThemedLayoutV2>
                                    </Authenticated>
                                }
                            >
                                <Route index element={<MiniDashboardLayoutList />} />

                                <Route path="/sdks">
                                    <Route index element={<AppstoreSDKList />} />
                                    <Route
                                        path="create"
                                        element={<AppstoreSDKCreate />}
                                    />                                    
                                    <Route
                                        path="clone/:id"
                                        element={<AppstoreSDKCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<AppstoreSDKEdit />}
                                    />
                                </Route> 

                                <Route path="/sdk-miniapps">
                                    <Route index element={<AppstoreMiniAppOfList />} />
                                    <Route
                                        path="create"
                                        element={<AppstoreMiniAppOfCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<AppstoreMiniAppOfEdit />}
                                    />
                                </Route>

                                <Route path="/miniapp-versions">
                                    <Route index element={<AppstoreMiniAppVersionList />} />
                                    <Route
                                        path="create"
                                        element={<AppstoreMiniAppVersionCreate />}
                                    />
                                    <Route
                                        path="clone/:id"
                                        element={<AppstoreMiniAppVersionCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<AppstoreMiniAppVersionEdit />}
                                    />
                                    <Route
                                        path=":id/permissions"
                                        element={<AppstoreMiniAppVersionPermission />}
                                    />
                                </Route>

                                <Route path="/miniapps">
                                    <Route index element={<AppstoreMiniappList />} />
                                    <Route
                                        path="create"
                                        element={<AppstoreMiniappCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<AppstoreMiniappEdit />}
                                    />
                                    <Route
                                        path="show/:id"
                                        element={<AppstoreMiniappShow />}
                                    />
                                </Route> 

                                <Route path="/permissions">
                                    <Route index element={<AppstorePermissionList />} /> 
                                </Route> 

                                <Route path="/layouts">
                                    <Route index element={<MiniDashboardLayoutList />} />
                                    <Route
                                        path="create"
                                        element={<MiniDashboardLayoutCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<MiniDashboardLayoutEdit />}
                                    />
                                    <Route path="clone/:id" element={<MiniDashboardLayoutCreate />} />
                                </Route> 

                                <Route path="/sections">
                                    <Route index element={<MiniDashboardSectionList />} />
                                    <Route
                                        path="create"
                                        element={<MiniDashboardSectionCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<MiniDashboardSectionEdit />}
                                    />
                                    <Route
                                        path="clone/:id"
                                        element={<MiniDashboardSectionCreate />}
                                    />
                                </Route> 

                                <Route path="/layout-sections">
                                    <Route index element={<MiniDashboardSectionOfList />} />
                                    <Route
                                        path="create"
                                        element={<MiniDashboardSectionOfCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<MiniDashboardSectionOfEdit />}
                                    />
                                </Route> 

                                <Route path="/section-miniapps">
                                    <Route index element={<MiniDashboardMiniAppOfList />} />
                                    <Route
                                        path="create"
                                        element={<MiniDashboardMiniAppOfCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<MiniDashboardMiniAppOfEdit />}
                                    />
                                </Route> 

                                <Route path="/translates">
                                    <Route index element={<AppstoreTranslateList />} />
                                    <Route
                                        path="edit/:id"
                                        element={<AppstoreTranslateEdit />}
                                    />
                                </Route> 

                                <Route path="/users">
                                    <Route index element={<AppstoreUserList />} />
                                    <Route
                                        path="create"
                                        element={
                                            <CanAccess fallback={<div>Unauthorized!</div>}>
                                                <AppstoreUserCreate />
                                            </CanAccess>
                                        }
                                    />                       
                                    <Route
                                        path="edit/:id"
                                        element={<AppstoreUserEdit />}
                                    />
                                </Route> 

                            </Route>

                            <Route
                                element={
                                    <Authenticated fallback={<Outlet />}>
                                        <NavigateToResource resource="sdks" />
                                    </Authenticated>
                                }
                            >
                                <Route
                                    path="/login"
                                    element={
                                        <LoginPage />
                                    }
                                />
                            </Route>

                            <Route
                                element={
                                    <Authenticated>
                                        <ThemedLayoutV2
                                            Header={Header}
                                            Title={Title}
                                        >
                                            <Outlet />
                                        </ThemedLayoutV2>
                                    </Authenticated>
                                }
                            >
                                <Route path="*" element={<ErrorComponent />} />
                            </Route>
                        </Routes>
                        <UnsavedChangesNotifier />
                        <DocumentTitleHandler handler={customTitleHandler} />
                    </Refine>
                </RefineKbarProvider>
            </ConfigProvider>
        </BrowserRouter>
    );
};

export default App;
