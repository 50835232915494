import { useTranslate, useApiUrl, BaseKey } from "@refinedev/core";

import { Edit, getValueFromEvent, useSelect, useForm } from "@refinedev/antd";

import type { SelectProps } from 'antd';

import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    InputNumber,
    Radio,
    Select,
    Space,
    ButtonProps,
    Avatar,
    Typography,
    Upload,
    Grid,
    Tooltip,
    Flex, 
} from "antd";

const { Text, Paragraph } = Typography;

import {
  SmileOutlined,
  QuestionCircleOutlined,
  InfoCircleTwoTone,
} from '@ant-design/icons';

interface IFormValue {
    layout_name: string;
    status: number;
    note: string;
}

import { 
    ILayout, 
    ISection 
} from "interfaces";

type EditLayoutSectionProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
    editId?: BaseKey;
};

export const EditLayoutSection: React.FC<EditLayoutSectionProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    editId,
}) => {
    const t = useTranslate();
    const apiUrl = useApiUrl();
    const breakpoint = Grid.useBreakpoint();

    const { selectProps: layoutSelectProps } = useSelect<ILayout>({
        resource: "layouts",
        optionLabel: "layout_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });
    const { queryResult, selectProps: sectionSelectProps } = useSelect<ISection>({
        resource: "sections",
        optionLabel: "section_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    const sectionOptions: SelectProps['options'] = queryResult?.data?.data.map((item)=>{
        return {
            label: item.section_name,
            value: item.id,
            description: item.description,
        }
    });

    return (
        <Drawer
            {...drawerProps}
            width={breakpoint.sm ? "500px" : "100%"}
            zIndex={1001}
        >
            <Edit
                title="Edit Section Of Layout"
                resource="layout-sections"
                saveButtonProps={saveButtonProps}
                recordItemId={editId}
                contentProps={{
                    style: {
                        boxShadow: "none",
                    },
                    bodyStyle: {
                        padding: 0,
                    },
                }}
            >
                <Form {...formProps} layout="vertical">
                    <Form.Item
                        name={"dashboard_layout_id"}
                        label="Layout Name"
                        style={{ maxWidth: "893px" }}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select {...layoutSelectProps} />
                    </Form.Item>     
                    <Form.Item
                        name={"dashboard_section_id"}
                        label="Section Name"
                        style={{ maxWidth: "893px" }}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select {...sectionSelectProps} 
                            options={sectionOptions}
                            optionRender={(option) => { 
                                return (
                                    <Flex justify={"space-between"}>
                                        <span>{option.data.label}</span>
                                        {option.data.description && (
                                            <Tooltip title={option.data.description} color={"#2db7f5"}>
                                                <InfoCircleTwoTone />
                                            </Tooltip>
                                        )}
                                    </Flex>
                                )
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={"Ordering"}
                        name="ordering"
                        style={{ maxWidth: "893px" }}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>                      
                    <Form.Item
                        name={"status"}
                        label="Status"
                        style={{ maxWidth: "893px" }}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        hasFeedback
                    >
                        <Radio.Group>
                            <Radio value={1}>
                                {"Active"}
                            </Radio>
                            <Radio value={2}>
                                {"Inactive"}
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                     
                </Form>
                    
            </Edit>
        </Drawer>
    );
};
