import { FC, PropsWithChildren, useState } from "react";
import {
    useGetIdentity,
    useNavigation,
} from "@refinedev/core";

import { useModalForm } from "@refinedev/antd";

import { Tag, Flex, List, Checkbox, AutoComplete, Tabs, Radio, Space, Button, Form, Input, DatePicker, InputNumber, Modal, Select, Spin, Tooltip, Typography } from "antd";
const { Text, Title } = Typography;

import {
    IIdentity,
} from "interfaces";

type Props = {
    onClose?: () => void;
};

export const ChangePasswordPage: React.FC<Props> = ({
    onClose
}) => {

    const { list, goBack } = useNavigation();
    const { data: user } = useGetIdentity<IIdentity>();

    // Show Modal
    const [visibleShowModal, setVisibleShowModal] = useState<boolean>(false);

    const { formProps, submit, modalProps, close, onFinish, queryResult } = useModalForm({
        resource: "account/change-password",
        action: 'edit',
        id: user?.id,
        defaultVisible: true,
        warnWhenUnsavedChanges: true,
        submitOnEnter: false,
        successNotification: (data, values, resource) => {

            if (data?.data?.error == 0) {
                onClose?.()

                return {
                    message: 'Cập nhật mật khẩu thành công',
                    description: "Cập nhật thành công",
                    type: "success",
                };
            } else {
                return {
                    message: data?.data?.message,
                    description: "Cập nhật thất bại",
                    type: "error",
                };
            }
        },
    });

    const loading = queryResult?.isLoading;

    const onFinishHandler = (values: any) => {
        onFinish(values);
    };

    return (
        <Modal
            {...modalProps}
            title={
                <Title level={5}>{"Cập Nhật Mật Khẩu"}</Title>
            }
            confirmLoading={loading}
            width={500}
            // style={{ top: 20}}
            afterClose={() => {
                onClose?.()
            }}
            //footer={null}
            okText="Đổi mật khẩu"
            cancelText="Bỏ qua"
            visible={visibleShowModal}
            onCancel={() => { 
                setVisibleShowModal(false); 
                onClose?.();  
            }}
        >
            <Spin spinning={loading}>
                <br />
                <Form
                    {...formProps}
                    labelCol={{ span: 9 }}
                    wrapperCol={{ span: 15 }}
                    labelWrap 
                    onFinish={onFinishHandler}
                >
                    <Form.Item
                        label={"Mật khẩu hiện tại"}
                        name="password_current"
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng nhập mật khẩu hiện tại'
                            },
                            {
                                min: 6,
                                message: "Độ dài tối thiểu 6 ký tự",
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label={"Mật khẩu mới"}
                        name="password_new"
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng nhập mật khẩu mới'
                            },
                            {
                                min: 6,
                                message: "Độ dài tối thiểu 6 ký tự",
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label={"Nhập lại mật khẩu mới"}
                        name="password_new_retype"
                        dependencies={["password_new"]}
                        rules={[
                            {
                                required: true,
                                message: 'Vui lòng nhập lại mật khẩu mới'
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue("password_new") === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error(
                                            "Mật khẩu nhập lại chưa giống với mật khẩu mới!",
                                        ),
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
        
    );
};
