import { useTranslate, useApiUrl } from "@refinedev/core";

import { 
    Create, 
    getValueFromEvent, 
    useSelect 
} from "@refinedev/antd";

import type { SelectProps } from 'antd';

import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    InputNumber,
    Radio,
    Select,
    Space,
    ButtonProps,
    Avatar,
    Typography,
    Upload,
    Grid,
    Row,
    Col,
} from "antd";

const { Text } = Typography;

import { IMiniapp, ISection, IMiniappVersion } from "interfaces";

type CreateProductProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
    sectionId?: number;
};

export const CreateMiniappOfSection: React.FC<CreateProductProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    sectionId
}) => {
    const t = useTranslate();
    const apiUrl = useApiUrl();
    const breakpoint = Grid.useBreakpoint();

    const { queryResult, selectProps: miniappSelectProps } = useSelect<IMiniapp>({
        resource: "miniapps",
        optionLabel: "miniapp_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    const miniappOptions: SelectProps['options'] = queryResult?.data?.data.map((item)=>{
        return {
            label: item.miniapp_name,
            value: item.id,
            icon: item.icon,
        }
    });

    const { selectProps: sectionSelectProps } = useSelect<ISection>({
        resource: "sections",
        optionLabel: "section_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    return (
        <Drawer
            {...drawerProps}
            width={breakpoint.sm ? "500px" : "100%"}
            zIndex={1001}
        >
            <Create
                title="Miniapps Of Section" 
                resource="section-miniapps"
                saveButtonProps={saveButtonProps}
                goBack={false}
                contentProps={{
                    style: {
                        boxShadow: "none",
                    },
                    bodyStyle: {
                        padding: 0,
                    },
                }}
            >
                <Form
                        {...formProps}
                        layout="vertical"
                        initialValues={{ 
                            status: 1,
                            dashboard_section_id: sectionId 
                        }}
                    >
                    <Row gutter={20}>
                        <Col xs={24} lg={24}>
                            <Form.Item>
                                <Form.Item
                                    name="banner"
                                    valuePropName="fileList"
                                    getValueFromEvent={getValueFromEvent}
                                    noStyle
                                >
                                    <Upload.Dragger
                                        name="file"
                                        action={`${process.env.REACT_APP_UPLOAD_API_URL}/upload/icon`}
                                        listType="picture"
                                        maxCount={1}
                                        multiple
                                        style={{
                                            border: "none",
                                            width: "100%",
                                            background: "none",
                                        }}
                                    >
                                        <Space direction="vertical" size={2}>
                                            <Avatar
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    maxWidth: "200px",
                                                }}
                                                src="/images/product-default-img.png"
                                                alt="Banner Location"
                                            />
                                            <Text
                                                style={{
                                                    fontWeight: 800,
                                                    fontSize: "16px",
                                                    marginTop: "8px",
                                                }}
                                            >
                                                {"Banner Image"}
                                            </Text>
                                            <Text style={{ fontSize: "12px" }}>
                                                
                                            </Text>
                                        </Space>
                                    </Upload.Dragger>
                                </Form.Item>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={24}>
                            <Row gutter={10}>
                                <Col xs={24} lg={24}>
                                    <Form.Item
                                        name={"dashboard_section_id"}
                                        label="Section Name"
                                        style={{ maxWidth: "893px" }}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Select {...sectionSelectProps} />
                                    </Form.Item>     
                                    
                                    <Form.Item
                                        name={"link_url"}
                                        label="Link Url"
                                        style={{ maxWidth: "893px" }}
                                        rules={[{ type: 'string', min: 6 }, { type: 'url', whitespace: true}]}
                                        hasFeedback
                                    >
                                        <Input placeholder="(http:// | https://)" />
                                    </Form.Item>        

                                    <Form.Item
                                        name={"miniapp_id"}
                                        label="MiniApp Name"
                                        style={{ maxWidth: "893px" }}
                                    >
                                        <Select 
                                            {...miniappSelectProps}
                                            options={miniappOptions}
                                            optionRender={(option) => { 
                                                return (
                                                    <Space>
                                                        <span role="img" aria-label={option.data.label}>
                                                            <Avatar size={20} src={option.data.icon} /> 
                                                        </span>
                                                        {option.data.label}
                                                    </Space>
                                                )
                                            }} 
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={"Ordering"}
                                        name="ordering"
                                        style={{ maxWidth: "893px" }}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <InputNumber />
                                    </Form.Item>                      
                                    <Form.Item
                                        name={"status"}
                                        label="Status"
                                        style={{ maxWidth: "893px" }}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                        hasFeedback
                                    >
                                        <Radio.Group>
                                            <Radio value={1}>
                                                {"Active"}
                                            </Radio>
                                            <Radio value={2}>
                                                {"Inactive"}
                                            </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Create>
        </Drawer>
    );
};
