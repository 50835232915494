import { FC, PropsWithChildren, useState } from "react";
import {
    useGetIdentity,
    useNavigation,
} from "@refinedev/core";

import { useModalForm } from "@refinedev/antd";

import { Tag, Flex, List, Checkbox, AutoComplete, Tabs, Radio, Space, Button, Form, Input, DatePicker, InputNumber, Modal, Select, Spin, Tooltip, Typography } from "antd";
const { Paragraph, Text, Title } = Typography;

import { EditOutlined } from '@ant-design/icons';

import {
    IIdentity,
} from "interfaces";

type Props = {
    onClose?: () => void;
};

export const ProfilePage: React.FC<Props> = ({
    onClose
}) => {

    const { list, goBack } = useNavigation();
    const { data: user } = useGetIdentity<IIdentity>();

    // Show Modal
    const [visibleShowModal, setVisibleShowModal] = useState<boolean>(false);
    const [editEnable, setEditEnable] = useState<boolean>(false);

    const { formProps, submit, modalProps, close, onFinish, queryResult } = useModalForm({
        resource: "account/profile",
        action: 'edit',
        id: user?.id,
        defaultVisible: true,
        warnWhenUnsavedChanges: true,
        submitOnEnter: false,
        successNotification: (data, values, resource) => {

            if (data?.data?.error == 0) {
                onClose?.()

                return {
                    message: 'Cập nhật mật khẩu thành công',
                    description: "Cập nhật thành công",
                    type: "success",
                };
            } else {
                return {
                    message: data?.data?.message,
                    description: "Cập nhật thất bại",
                    type: "error",
                };
            }
        },
    });

    const loading = queryResult?.isLoading;
    const profileInfo = queryResult?.data?.data;
    console.log(profileInfo);

    const onFinishHandler = (values: any) => {
        onFinish(values);
    };

    const onChangeFullname = (value: any) => {
        console.log('onChangeFullname ', value)
    };

    return (
        <Modal
            {...modalProps}
            title={
                <Title level={5}>
                    {"Thông Tin Tài Khoản"} <EditOutlined style={{color: 'red', marginLeft: '10px'}} onClick={() => setEditEnable(!editEnable)} />
                </Title>
            }
            confirmLoading={loading}
            width={500}
            // style={{ top: 20}}
            afterClose={() => {
                onClose?.()
            }}
            footer={null}
            //okText="Đổi mật khẩu"
            cancelText="Bỏ qua"
            visible={visibleShowModal}
            onCancel={() => { 
                setEditEnable(false);
                setVisibleShowModal(false); 
                onClose?.();  
            }}
        >
            <Spin spinning={loading}>
                <br />
                <Form
                    {...formProps}
                    labelCol={{ span: 9 }}
                    wrapperCol={{ span: 12 }}
                    labelWrap 
                    onFinish={onFinishHandler}
                >
                    <Form.Item
                        label={"Họ tên"}
                        name="full_name" 
                    >
                        {!editEnable && 
                            <span className="ant-form-text">{profileInfo?.full_name}</span> 
                        }
                        {editEnable && 
                            <Input />
                        }
                    </Form.Item> 
                    <Form.Item
                        label={"Tên đăng nhập"} 
                        name="username" 
                    >
                        <span className="ant-form-text">{profileInfo?.username}</span>
                    </Form.Item> 
                    <Form.Item
                        label={"Điện thoai"} 
                        name="phone_number" 
                    >
                        {!editEnable && 
                            <span className="ant-form-text">{profileInfo?.phone_number}</span> 
                        }
                        {editEnable && 
                            <Input />
                        }
                    </Form.Item> 
                    <Form.Item
                        label={"Email"}
                        name="email"  
                    >
                        <span className="ant-form-text">{profileInfo?.email}</span> 
                    </Form.Item>
                    {editEnable && 
                        <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                Cập nhật thông tin
                            </Button>
                        </Form.Item> 
                    }
                </Form>
            </Spin>
        </Modal>
        
    );
};
