import { useTranslate, useApiUrl } from "@refinedev/core";

import { 
    Create, 
    getValueFromEvent, 
    useSelect 
} from "@refinedev/antd";
import type { SelectProps } from 'antd';

import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    InputNumber,
    Radio,
    Select,
    Space,
    ButtonProps,
    Avatar,
    Typography,
    Upload,
    Grid,
} from "antd";

const { Text } = Typography;

import { IMiniapp, ISdk, IMiniappVersion } from "interfaces";

type CreateProductProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
    sdkId?: number;
};

export const CreateMiniappOfSDK: React.FC<CreateProductProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    sdkId
}) => {
    const t = useTranslate();
    const apiUrl = useApiUrl();
    const breakpoint = Grid.useBreakpoint();

    const { queryResult, selectProps: miniappSelectProps } = useSelect<IMiniappVersion>({
        resource: "miniapp-versions",
        optionLabel: "miniapp_version",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });
    const miniappOptions: SelectProps['options'] = queryResult?.data?.data.map((item)=>{
        return {
            label: item.miniapp_version,
            value: item.id,
            icon: item.icon,
        }
    });

    const { selectProps: sdkSelectProps } = useSelect<ISdk>({
        resource: "sdks",
        optionLabel: "hostapp_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    return (
        <Drawer
            {...drawerProps}
            width={breakpoint.sm ? "500px" : "100%"}
            zIndex={1001}
        >
            <Create
                title="Add MiniApp Of SDK"
                resource="sdk-miniapps"
                saveButtonProps={saveButtonProps}
                goBack={false}
                contentProps={{
                    style: {
                        boxShadow: "none",
                    },
                    bodyStyle: {
                        padding: 0,
                    },
                }}
            >
                <Form 
                    {...formProps} 
                    layout="vertical"
                    initialValues={{ sdk_id: sdkId }}
                >
                    <Form.Item
                        name={"sdk_id"}
                        label="SDK Name"
                        style={{ maxWidth: "893px" }}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select {...sdkSelectProps} />
                    </Form.Item>     
                    <Form.Item
                        name={"miniapp_version_id"}
                        label="MiniApp Version"
                        style={{ maxWidth: "893px" }}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select {...miniappSelectProps} 
                            options={miniappOptions}
                            optionRender={(option) => { 
                                return (
                                    <Space>
                                        <span role="img" aria-label={option.data.label}>
                                            <Avatar size={20} src={option.data.icon} /> 
                                        </span>
                                        {option.data.label}
                                    </Space>
                                )
                            }} 
                        />
                    </Form.Item>
                    <Form.Item
                        label={"Ordering"}
                        name="ordering"
                        style={{ maxWidth: "893px" }}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>    
                     
                </Form>
            </Create>
        </Drawer>
    );
};
