import { useTranslate, useApiUrl } from "@refinedev/core";

import { 
    Create, 
    getValueFromEvent, 
    useSelect 
} from "@refinedev/antd";

import type { SelectProps } from 'antd';

import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    InputNumber,
    Radio,
    Select,
    Space,
    ButtonProps,
    Avatar,
    Typography,
    Upload,
    Grid,
    Tooltip,
    Flex,
} from "antd";

const { Text } = Typography;

import {
  SmileOutlined,
  QuestionCircleOutlined,
  InfoCircleTwoTone,
} from '@ant-design/icons';

import { 
    ILayout, 
    ISection 
} from "interfaces";

type CreateProductProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
    layoutId?: number;
};

export const CreateLayoutSection: React.FC<CreateProductProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    layoutId
}) => {
    const t = useTranslate();
    const apiUrl = useApiUrl();
    const breakpoint = Grid.useBreakpoint();

    const { selectProps: layoutSelectProps } = useSelect<ILayout>({
        resource: "layouts",
        optionLabel: "layout_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });
    const { queryResult, selectProps: sectionSelectProps } = useSelect<ISection>({
        resource: "sections",
        optionLabel: "section_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    const sectionOptions: SelectProps['options'] = queryResult?.data?.data.map((item)=>{
        return {
            label: item.section_name,
            value: item.id,
            description: item.description,
        }
    });
    
    return (
        <Drawer
            {...drawerProps}
            width={breakpoint.sm ? "500px" : "100%"}
            zIndex={1001}
        >
            <Create
                title="Add Section Of Layout"
                resource="layout-sections"
                saveButtonProps={saveButtonProps}
                goBack={false}
                contentProps={{
                    style: {
                        boxShadow: "none",
                    },
                    bodyStyle: {
                        padding: 0,
                    },
                }}
            >
                <Form 
                    {...formProps} 
                    layout="vertical"
                    initialValues={{
                        dashboard_layout_id: layoutId,
                        status: 1,
                        ...formProps.initialValues,
                    }}
                >
                    <Form.Item
                        name={"dashboard_layout_id"}
                        label="Layout Name"
                        style={{ maxWidth: "893px" }}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select {...layoutSelectProps} />
                    </Form.Item>     
                    <Form.Item
                        name={"dashboard_section_id"}
                        label="Section Name"
                        style={{ maxWidth: "893px" }}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select {...sectionSelectProps} 
                            options={sectionOptions}
                            optionRender={(option) => { 
                                return (
                                    <Flex justify={"space-between"}>
                                        <span>{option.data.label}</span>
                                        {option.data.description && (
                                            <Tooltip title={option.data.description} color={"#2db7f5"}>
                                                <InfoCircleTwoTone />
                                            </Tooltip>
                                        )}
                                    </Flex>
                                )
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={"Ordering"}
                        name="ordering"
                        style={{ maxWidth: "893px" }}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>                      
                    <Form.Item
                        name={"status"}
                        label="Status"
                        style={{ maxWidth: "893px" }}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        hasFeedback
                    >
                        <Radio.Group>
                            <Radio value={1}>
                                {"Active"}
                            </Radio>
                            <Radio value={2}>
                                {"Inactive"}
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                     
                </Form>
            </Create>
        </Drawer>
    );
};
